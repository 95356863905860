import React, { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import HeartableLink from '../HeartableLink'

const ClippingLink = styled(RouterLink)`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  width: auto;
  min-height: 6em;
  max-height: 12em;
  min-width: 12em;
  max-width: 25vw;
  @media (min-width: 32rem) {
    max-width: 12em;
  }
  perspective: 24em;
  color: inherit;
  ${props => props.type === 'scraped' && `
    .year {}
  `}
  ${props => props.type === 'trello' && `
    .year {background: #8cf;}
  `}
  &:hover {
    z-index: 3;
  }
  &:hover,
  &:focus-within {
    background: #eee;
    img {
      transform: scale(1.5);
    }
    .detail {
      transform: scale(1.25);
    }
  }
  &.active {
    filter: sepia(1) hue-rotate(-180deg);
  }
  &:visited {
    background-color: #eee;
  }
  img {
    box-shadow: .5em .5em 1em 0 #0004;
    transition: .5s ease all;
    max-height: inherit;
    z-index: 3;
  }
  &:hover {
    img {
      box-shadow: 0 0 1em 0 #0006;
    }
  }
  .detail {
    position: absolute;
    padding: .25em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: .75s ease all;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: center;
    background-position: top center;
    background-size: cover;
    .title {
      opacity: 0;
      text-transform: uppercase;
      transition: .75s ease all;
      span {
        background: #fff;
      }
      span, mark {
        padding: .25em;
        display: inline;
      }
    }
    .year {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      background: #08f;
      padding: .25em;
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &:visited .detail {
    color: inherit;
  }
  img {
    transition: .75s ease all;
    height: 100%;
  	width: auto;
  	max-width: 100%;
  }
  .cat {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`

// const IMG = styled.div`
//   max-height: 8em;
//   height: auto;
//   max-width: 16em;
//   /* overflow: hidden; */
//   text-align: center;
//   flex: 1;
//   img {
//     margin: auto;
//   }
// `

function randomNumber(min, max) {
  const range = max - min
  const rand = (Math.random() * range) - (range / 2)
  return rand
}

function Link({ size = 0, to, clipping, children, key, ...props }) {
  const [randomRotate] = useState(() => randomNumber(-4, 4))
  // let record = clipping.trello || {}
  let { title } = clipping
  let id = clipping.id
  let imageSrc = clipping.thumb?.url || clipping.image?.url
  // if (clipping.scraped) imageSrc = `/clippings/${clipping.scraped.Code}.jpg`
  // if (clipping.trello?.attachments) {
  //   let imgs = record.attachments.filter(a => a.mimeType?.includes('image'))
  //   let scaledPreviews = imgs.map(img => img.previews.find(p => p.scaled))
  //   if (scaledPreviews.length) imageSrc = scaledPreviews[0].url
  // }
  return (<ClippingLink type={clipping.type} to={to} id={id} title={title} tabIndex={0} style={{ transform: `scale(.92) rotate(${randomRotate}deg)` }} {...props}>
    <div className="detail" style={{ backgroundImage: `url(${imageSrc})` }}>
      {clipping.year > 0 ? <div className="year">{clipping.year}</div> : null}
      <HeartableLink id={id} readonly hideOff />
    </div>
  </ClippingLink>)
}
// {imageSrc && <IMG style={{ transform: `scale(.92) rotate(${randomRotate}deg)` }}><img src={imageSrc} alt={name} loading="lazy" /></IMG>}

export default Link
