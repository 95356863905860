import Lists from './lists.json'
import scrapedClippings from './clippings.json'
import trelloArchive from './trello.json'
import tweets from './tweets'

import { WEBSITE_DOMAIN, redactedClippings } from '../settings'

let host = `${global.location?.protocol || 'https:'}//${global.location?.hostname || WEBSITE_DOMAIN}`
if (global.location?.port && global.location?.port !== 80) host += `:${global.location?.port}`

function getImages(card) {
  let image = {}
  let thumb = {}
  if (card.attachments) {
    let imgAttachments = card.attachments.filter(a => ['.jpeg', '.jpg', '.png'].some(ext => a.url.toLowerCase().includes(ext)))
    let [scaledPreviewSmall] = imgAttachments.map(a => a.previews[3] || a.previews[0])
    let [scaledPreviewLarge] = imgAttachments.map(a => a.previews[a.previews.length - 1])
    if (scaledPreviewLarge) {
      let preview = scaledPreviewLarge
      image.url = preview.url
      image.width = preview.width
      image.height = preview.height
    }
    if (scaledPreviewSmall) {
      let { url, width, height } = scaledPreviewSmall
      thumb = { url, width, height }
    }
  }
  return { image, thumb }
}

let normalizedLists = Lists.map(list => {
  let entries = []
  let aliases = ([list.name, ...list.board.split('/')]).map(s => s.toLowerCase())
  let slug = list.name.toLowerCase().replace(' ', '-')
  let url = `/list/${slug}`
  let mergedTrelloCards = []
  scrapedClippings.forEach((clipping, index) => {
    const tech = clipping['Technology'].toLowerCase()
    if (aliases.includes(tech) || aliases.some(alias => clipping['OCR'].toLowerCase().substr(0, 258).includes(alias))) {
      let [year] = (clipping['Date/Source'].match(/\d\d\d\d/) || [1])
      let newspaperDotComImageID = clipping.Code
      let id = `sc-${index}`
      let title, description
      // title = clipping.OCR.split('.')[0].substr(0, 64)
      // description = `📰 ${clipping.Publication}\n📅 ${clipping['Date/Source']}`

      title = `📰 ${clipping.Publication}`
      description = `📅 ${clipping['Date/Source']}`

      let images = {
        image: {
          url: `${host}/clippings/${clipping.Code}.jpg`
        },
        thumb: {
          url: `${host}/clippings/thumbs/${clipping.Code}.jpg`
        }
      }

      let trelloMatch = trelloArchive.cards.find(card => card.attachments?.some(att => att.url.includes(newspaperDotComImageID)))
      if (trelloMatch) {
        let i = trelloArchive.cards.indexOf(trelloMatch)
        mergedTrelloCards.push(i)
        id = `m-${id}-${i}`
        // images = getImages(trelloMatch)
        title = trelloMatch.name
      }

      if (redactedClippings.includes(id)) return

      entries.push({
        type: !trelloMatch ? 'scraped' : 'merged',
        id,
        index,
        year,
        title, description,
        url: `${url}/clippings/${year}/${id}`,
        ...images,
        scraped: clipping,
        trello: trelloMatch,
        list
      })
    }
  })
  trelloArchive.cards.forEach((card, index) => {
    if (mergedTrelloCards.includes(index)) return
    const techs = (card.list?.name || '').toLowerCase().split('/')
    if (techs.some(tech => aliases.includes(tech)) || aliases.some(alias => card.desc.toLowerCase().includes(alias))) {
      let [year] = (card.name.match(/\d\d\d\d/) || [1])
      let id = `tr-${index}`
      let title, description
      title = card.name
      description = card.desc

      const images = getImages(card)

      entries.push({
        type: 'trello',
        id,
        index,
        year,
        title, description,
        url: `${url}/clippings/${year}/${id}`,
        ...images,
        trello: card,
        list
      })
    }
  })

  entries = entries.sort((e0, e1) => e0.year > e1.year ? 1 : -1)
  return { list: { ...list, aliases, slug, url, tweets: tweets[slug] || [] }, entries }
})

export default normalizedLists
