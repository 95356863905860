import React from 'react'

import PAGE from './PAGE'

function Contact() {
  return (<PAGE title="About">
    <p>Pessimists Archive™ is a project to educate people on and archive the history of technophobia and moral panics. We believe the best antidote to fear of the new is looking back at fear of the old.</p>

    <p>Only by looking back at fears of old things when they were new, can we have rational constructive debates about emerging technologies today that avoids the pitfalls of moral panic and incumbent protectionism.</p>

    <h2>CONTACT</h2>
    <p><img src="/contact-us.png" alt="" width="80%" /></p>
    <p>Want to partner, advertise, license our IP or contribute a clipping to the archive? Reach out! <a href="mailto:louis@hey.com">louis@hey.com</a></p>

    <h2>SUPPORTERS</h2>
    <p>Pessimists Archive is supported by the following orgs in the name of encouraging and defending
technological progress.</p>

    <p><img src="/received_1351430398693254.jpeg" width="100" /> &nbsp; <img src="/tba.jpg" width="100" /> &nbsp; <img src="/tba.jpg" width="100" /></p>
    
    <p>⚙️ Site developed by <a href="https://olibeatson.com">Oli Beatson</a></p>
  </PAGE>)
}

export default Contact
