import React from 'react'
import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

const LinkCSS = `
  background: var(--button-bg);
  color: inherit;
  border: 1px solid #6664;
  margin: 1em;
  padding: .5em 1em;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: .25em;
  transition: .2s ease all;
  box-shadow: 0 0 .5em 0 #fff8;
  &:hover, &:focus {
    box-shadow: .5em .5em .5em 0 #0004;
    border-color: #eee;
    transform: scale(1.1);
    transform-origin: 75% 75%;
    background: #08f;
    border-color: #048;
    color: #fff;
  }
`

const Explore = styled(Link)`
  ${LinkCSS}
`

const ButtonExternal = styled.a`
  ${LinkCSS}
`

function Button(props) {
  if (props.to.startsWith("http")) return <ButtonExternal {...props} href={props.to} />
  return (<Explore {...props} />)
}

export default Button
