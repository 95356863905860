import { useState } from 'react'

import styled from 'styled-components'

const OL = styled.ol`
  text-align: left;
  list-style: none;
`

const Val = styled.span`
  display: inline-block;
  padding: .5em;
`

const Key = styled.b`
  display: inline-block;
  padding: .5em;
`

function Preview({ obj }) {
  if (obj === undefined) return <Val>undefined</Val>
  if (obj === null) return <Val>null</Val>
  if (['string', 'number'].includes(typeof obj)) return <Val>{obj}</Val>
  return (<Val>{`{${Object.keys(obj).length || ''}}`}</Val>)
}

function Obj({ obj }) {
  const [open, setOpen] = useState([])
  if (obj === undefined) return <Val>undefined</Val>
  if (obj === null) return <Val>null</Val>
  if (typeof obj === 'string') return <Val>{obj}</Val>
  return (<OL>
    {Object.keys(obj).map(key => (<li>
      <Key onClick={() => setOpen(open.includes(key) ? open.filter(k => k !== key) : open.concat(key))}>{key}</Key>
      {open.includes(key)
        ? <Obj obj={obj[key]} />
        : <Preview obj={obj[key]} />  }
    </li>))}
  </OL>)
}

function Log({ data }) {
  if (!(global.location?.host?.includes('localhost') || global.location?.host?.includes('herokuapp'))) return null
  return (<section>
    <Obj obj={data} />
  </section>)
}

export default Log
