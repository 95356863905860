const tweets = {
  masks: [
    ['1918 writer says Spanish Flu defeated, forget about masks and smile! (the worst was yet to come) <a href="https://t.co/nXhDp58Vqv">pic.twitter.com/nXhDp58Vqv</a>', 'Pessimists Archive (@PessimistsArc)', 'https://twitter.com/PessimistsArc/status/1303841005330989057?ref_src=twsrc%5Etfw', 'September 9, 2020']
// <blockquote class="twitter-tweet"><p lang="en" dir="ltr">1918 coverage of flu mask laws ending... <a href="https://twitter.com/hashtag/dejaflu?src=hash&amp;ref_src=twsrc%5Etfw">#dejaflu</a> <a href="https://t.co/LuBNG3GPRG">pic.twitter.com/LuBNG3GPRG</a></p>&mdash; Pessimists Archive (@PessimistsArc) <a href="https://twitter.com/PessimistsArc/status/1304207285519036417?ref_src=twsrc%5Etfw">September 10, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
//
// <blockquote class="twitter-tweet"><p lang="en" dir="ltr">No comment... <br><br>(apart from listen to our podcast on 1918 anti-mask movement: <a href="https://t.co/EAbGdTWwac">https://t.co/EAbGdTWwac</a>) <a href="https://t.co/VFFQdqorpf">pic.twitter.com/VFFQdqorpf</a></p>&mdash; Pessimists Archive (@PessimistsArc) <a href="https://twitter.com/PessimistsArc/status/1278791522591420417?ref_src=twsrc%5Etfw">July 2, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
//
// <blockquote class="twitter-tweet"><p lang="en" dir="ltr">1918 flu pandemic:<br><br>“Opponents of the flu mask ordinances, including a small representation of businessmen &amp; a goodly representation of Christian Scientists, invaded the city council meeting last night in a vain endeavor to stampeded the council into repealing the ordinance.&quot; <a href="https://t.co/2IzA5aZlMo">pic.twitter.com/2IzA5aZlMo</a></p>&mdash; Pessimists Archive (@PessimistsArc) <a href="https://twitter.com/PessimistsArc/status/1318925799366557697?ref_src=twsrc%5Etfw">October 21, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
//
// <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Dec 18, 1918: <a href="https://twitter.com/hashtag/Maskholes?src=hash&amp;ref_src=twsrc%5Etfw">#Maskholes</a> storm council meeting to demand mask law be repealed <a href="https://t.co/nkAy5bBQfK">https://t.co/nkAy5bBQfK</a> <a href="https://t.co/sHDN18TblZ">pic.twitter.com/sHDN18TblZ</a></p>&mdash; Pessimists Archive (@PessimistsArc) <a href="https://twitter.com/PessimistsArc/status/1290763525468704769?ref_src=twsrc%5Etfw">August 4, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
// '
  ]
}

export default tweets
