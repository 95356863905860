import React from 'react'
import styled from 'styled-components'
import Explore from '../ExploreLink'

const NEWSLETTER = styled.section`
  padding: 1em;
  iframe {
  	max-width: min(100vw, 100%);
  }
`

const H = styled.h1`
  text-transform: none !important;
  margin: 0 auto !important;
  position: relative;
  font-size: 1.1em;
  strong {
    color: orange;
  }
`

function NewsletterEmbed({ showCallToAction = true }) {
  return (<NEWSLETTER>
    <H>Pessimists Archive <strong>Newsletter</strong></H>
    <iframe title="substack" src="https://pessimistsarchive.substack.com/embed" width="420" height="120" frameborder="0" scrolling="no" style={{ marginTop: '-.5em', marginBottom: showCallToAction ? '-2em' : 0 }} />
    {showCallToAction
      ? <Explore style={{ marginTop: '.5em', marginBottom: '2em' }} to="https://pessimistsarchive.substack.com">Read Latest</Explore>
      : null}
  </NEWSLETTER>)
}

export default NewsletterEmbed
