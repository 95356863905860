import React from 'react'
import styled from 'styled-components'

const PAGE = styled.article`
  flex: 1;
  h1 {
    text-transform: uppercase;
    margin: .5em auto;
  }
  iframe {
    max-width: 100vw;
    margin: 0 -1em;
  }
`

const Content = styled.div`
  max-width: 32em;
  position: relative;
  z-index: 4;
  padding: .5em 1em;
  background: var(--main-bg-alpha);
  backdrop-filter: blur(3px);
  margin: auto;
  border-radius: 1em;
  p {
    a {
      color: #08f;
      display: inline-block;
      padding: .25em;
      margin: 0 -.25em;
      &:hover, &:focus {
        color: #fff;
        background: #08f;
        text-decoration: none;
      }
    }
  }
`

function Page({ title, children, contentStyle = {} }) {
  return (<PAGE>
    <h1>{title}</h1>
    <Content style={contentStyle}>
      {children}
    </Content>
  </PAGE>)
}

export default Page
