import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'

import ClippingList from '../Clipping/List'
import PhysicalImages from '../PhysicalImages'
import Home from '../Pages/Home'

import Intro from './Intro'
import Timeline from './Timeline'

import lists from '../data/lists'

const LISTS = styled.div`
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  p a {
    color: #08f;
    /* display: inline-block; */
    padding: .25em;
    &:hover, &:focus {
      color: #fff;
      background: #08f;
      text-decoration: none;
    }
  }
`

const CATEGORY = styled.article`
  /* overflow: auto; */
  padding-bottom: 8em;
  flex: 1;
  scroll-behavior: smooth;
`

const MAX_IMAGES = 10

const NotFound = styled.div`
  position: relative;
  margin: auto;
  padding: 1em;
  max-width: 32em;
  z-index: 4;
  border-radius: 1em;
  background: var(--main-bg-alpha);
  backdrop-filter: blur(3px);
`

function randomBetween(low, high) {
  let range = high - low
  let num = Math.floor(Math.random() * range)
  return num
}

function randomItem(list) {
  return list[randomBetween(0, list.length - 1)]
}

function randomItems(list, n) {
  let items = []
  if (n >= list.length) return list
  while (items.length < Math.min(n, list.length)) {
    let item = randomItem(list)
    if (!items.includes(item)) items.push(item)
    // items.push(item)
  }
  return items
}

const allEntries = lists.map(list => list.entries).reduce((prev, current) => {
  return [...prev, ...current]
}, [])

function Index({ listId, year, index, dots = [], showClippings = false, children }) {
  let listIndex, list
  if (listId) {
    list = lists.find(item => item.list?.slug === listId.toLowerCase())
    listIndex = lists.indexOf(list)
  }
  let images = randomItems((list?.entries || allEntries)
    .filter(e => e?.trello?.cover?.scaled?.some(img => img.scaled && (img.width / img.height) > 0.5))
    .map(entry => {
      if (entry.trello) {
        let card = entry.trello
        let img = card.cover?.scaled?.find(img => img.scaled && ((img.width / img.height) > 0.5) && img.width > 200)
        let { url, width, height } = img
        return { url, width, height, scale: .5 }
      } else {
        return { url: entry.image.url }
      }
    })
    , MAX_IMAGES)
  let randomList = randomItem(lists.filter(list => list.entries.length))
  let randomClipping = randomItem(randomList.entries)

  useEffect(() => {
    let oldTitle = document.title
    if (list?.list?.emoji) document.title = `${list.list.emoji} ${list.list.name} - Pessimists Archive`
    return () => {
      document.title = oldTitle
    }
  }, [list?.list])

  return (<LISTS>
    <Route exact path={["/", "/list/:listId"]} render={() => <PhysicalImages images={images} />} />
    <Route exact path="/" render={() => <Home randomClipping={randomClipping} />} />
    <Route path="/list/:listId/:subpage?/:year?/:index?" render={({ match: { params: { listId, subpage, year, index } } }) => (<CATEGORY>
      {list
        ? (<>
          <Intro list={list} listIndex={listIndex} subpage={subpage} />
          {subpage === 'clippings' && <ClippingList list={list} items={list.entries} cat={listId} year={year} index={index} />}
        </>)
        : <NotFound><h1>List "{listId}" not found</h1></NotFound>}
    </CATEGORY>)} />
    {children}
    <Timeline lists={lists} category={listId} year={year} />
  </LISTS>)
}

export default Index
