import React from 'react'
import styled from 'styled-components'

import PAGE from './PAGE'
import Explore from '../ExploreLink'
import Tweet from '../Tweet'
import NewsletterEmbed from '../components/NewsletterEmbed'

let twitterSrc = '//platform.twitter.com/widgets.js'

const WelcomeText = () => {
  return (<div>
    {/*/}<style>
      {"@font-face {"}
        font-family: 'TT2020StyleE';
        src: url("/TT2020StyleE-Regular.tff") format("truetype");
      {"}"}
    </style>{/**/}
    <svg width="457px" height="298px" style={{ maxWidth: 'calc(100vw - 2em)', maxHeight: 'calc((100vw - 2em) * (2 / 3))' }} viewBox="0 0 457 298" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Group</title>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stop-color="#FFFFFF" offset="0%"></stop>
          <stop stop-color="#FBFBFB" offset="100%"></stop>
        </linearGradient>
        <path d="M0,19 L443,19 C441.619938,66.5708975 440.929907,111.98345 440.929907,155.237659 C440.929907,198.491867 441.619938,241.74598 443,285 C359.819192,284.143317 282.869937,283.714976 212.152234,283.714976 C141.434531,283.714976 70.71712,284.143317 0,285 C0.649084249,226.134663 0.973626374,181.80133 0.973626374,152 C0.973626374,122.19867 0.649084249,77.8653366 0,19 Z" id="path-2"></path>
        <filter x="-2.6%" y="-3.6%" width="105.2%" height="108.6%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="3.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.223363904   0 0 0 0 0.199284944   0 0 0 0 0.0296314691  0 0 0 0.165182474 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <polygon id="path-4" points="153 11.2477876 153 38 303 38 295.711984 -2.10942375e-15 153 0"></polygon>
        <filter x="-3.3%" y="-13.2%" width="106.7%" height="126.3%" filterUnits="objectBoundingBox" id="filter-5">
          <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
          <feColorMatrix values="0 0 0 0 0.632982337   0 0 0 0 0.618754691   0 0 0 0 0.560420947  0 0 0 0.212958916 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(7.000000, 4.000000)">
          <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlinkHref="#path-2"></use>
            <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2"></use>
          </g>
          <polygon id="Path" fill-opacity="0.572415865" fill="#FDD833" points="21 67.9198882 21 82 196 82 187.497315 62 21 62"></polygon>
          <text id="Pessimists-Archive-i" fontSize="1em" font-weight="normal" line-spacing="25" letter-spacing="-0.6" fill="#6580A5">
            <tspan x="24" y="74">Pessimists Archive is a project to jog our </tspan>
            <tspan x="24" y="99">collective memories about the hysteria, </tspan>
            <tspan x="24" y="124">technophobia and moral panic that often </tspan>
            <tspan x="24" y="149">greets new technologies, ideas and trends. </tspan>
            <tspan x="24" y="174"></tspan>
            <tspan x="24" y="199">Explore the timeline of technologies below </tspan>
            <tspan x="24" y="224">and follow us on </tspan>
            <a href="https://twitter.com/pessimistsarc"><tspan x="181.182" y="224" text-decoration="underline">Twitter</tspan></a>
            <tspan x="245.904" y="224">, </tspan>
            <a href="https://instagram.com/pessimistsarc"><tspan x="264.396" y="224" text-decoration="underline">Instagram</tspan></a>
            <tspan x="347.61" y="224"> and </tspan>
            <a href="https://www.tiktok.com/@pessimistsarchive"><tspan x="24" y="249" text-decoration="underline">TikTok</tspan></a>
            <tspan x="79.476" y="249">. </tspan>
          </text>
          <g id="Path">
            <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlinkHref="#path-4"></use>
            <use stroke="#F8F2CE" stroke-width="1" fill="#FFFAE5" fill-rule="evenodd" xlinkHref="#path-4"></use>
          </g>
        </g>
      </g> 
    </svg>
  </div>)
}

function Home() {
  return (<PAGE contentStyle={{ background: 'white', fontFamily: 'TT2020StyleE, TT2020 Style E, Courier, monospace', marginBottom: '6em' }}>
    <WelcomeText />
    <NewsletterEmbed />
  </PAGE>)
}

// OLD CONTENT
// <p>Welcome to <b>Pessimists Archive</b>, a project created to jog our
//   collective memories about the hysteria, technophobia and moral panic
//   that often greets new technologies, ideas and trends.</p>
// <p>Explore the timeline below and don’t forget to follow us on&nbsp;
//   <a href="https://twitter.com/pessimistsarc">Twitter</a> and&nbsp;
//   <a href="https://instagram.com/pessimistsarc">Instagram</a>.
// </p>
// <Explore to={randomClipping?.url}>❓ I'm Feeling Pessimistic</Explore>
// <Explore to="/favs"><span style={{ color: 'red', fontSize: '1.25em', lineHeight: 1 }}>❤️</span> Favs & 🎛️ Settings</Explore>
// <Tweet href="https://twitter.com/sapinker/status/1127217607206146052?ref_src=twsrc%5Etfw" date="May 11, 2019" author="Steven Pinker (@sapinker)">
//   Pessimists Archive is invaluable in putting today’s tech moral panics in historical perspective. <a href="https://t.co/rHcUXiV5pk">https://t.co/rHcUXiV5pk</a>
// </Tweet>

export default Home
