import React, { useState } from 'react'
// import { NavLink as Link } from 'react-router'
import styled from 'styled-components'

import Lists from './data/lists'
import ClippingLink from './Clipping/Link'

const HEARTS_KEY = 'hearts'

const { localStorage } = global

const Hearts = {
  get: () => JSON.parse(localStorage.getItem(HEARTS_KEY)) || [],
  add: heart => localStorage.setItem(HEARTS_KEY, JSON.stringify([...Hearts.get(), heart])),
  del: heart => localStorage.setItem(HEARTS_KEY, JSON.stringify(Hearts.get().filter(h => h !== heart)))
}

const LIST = styled.article`
  flex: 0;
`

const None = styled.div`
  /* color: #888; */
`

function Fav({ id }) {
  if (!id) return <></>
  let card
  let list = Lists.find(list => list.entries.some(entry => entry.id === id))
  if (list) card = list.entries.find(entry => entry.id === id)
  if (!card) return <div>No {id}</div>
  return <ClippingLink to={card.url} clipping={card} />
}

function Favs() {
  const hearts = Hearts.get()
  const [showPhysics, setShowPhysics] = useState(localStorage?.getItem('show-physics') === 'true')
  return (<LIST>
    <h1>❤️ Favorite Articles</h1>
    {hearts?.length
      ? hearts.map(id => <Fav id={id} />)
      : <None>Hit the ❤️ symbol on your favourite clippings to save them on this device.</None>}
    <h2>🎛️ Settings</h2>
    <p><label><input type="checkbox" checked={showPhysics} onChange={e => [localStorage.setItem('show-physics', e.target.checked), setShowPhysics(e.target.checked)]} /> Show image physics</label></p>
  </LIST>)
}

export default Favs
