import React from 'react'

import PAGE from './PAGE'

function Manifesto() {
  return (<PAGE title="Manifesto">
    <p>The Pessimists Archive newsletter gives you a weekly roundup of new and old finds shared on our social accounts.
     Each clipping has a little more context and information than on social media.</p>
    <iframe title="substack" src="https://pessimistsarchive.substack.com/embed" width="480" height="320"  frameborder="0" scrolling="no"></iframe>
  </PAGE>)
}

export default Manifesto
