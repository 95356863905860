import React, { useState, useEffect, useRef } from 'react'
import { NavLink as Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import HeartableLink from '../HeartableLink'
import Log from '../Log'
import Share from './Share'

import { WEBSITE_DOMAIN } from '../settings'

const slideIn = keyframes`
  from {
    max-height: 0;
    justify-content: stretch;
    overflow: clip;
  }
  to {
    max-height: 300vh;
  }
`

const ARTICLE = styled.article`
  text-align: center;
  transition: .5s ease all;
  transform: scale(1);
  transform-origin: middle;
  /* animation: ${slideIn} 4s; */
  overflow: auto;
  box-sizing: border-box;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  section {
    position: relative;
    > button {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      font-size: 1.5rem;
      &.left {
        right: auto;
        left: 0;
      }
    }
  }
  .close {
    border-radius: 50%;
    color: #888;
    font-weight: bold;
    font-size: 2.5em;
    align-self: flex-start;
    width: 1em;
    height: auto;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    transition: .2s ease all;
    transform: scale(.8);
    &:hover, &:focus {
      color: #f00;
      transform: scale(1);
    }
  }
  h1 {
    font-size: 1.5em;
    text-align: justify;
    display: flex;
    margin: 1rem auto;
    gap: .5em;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    a {
      color: inherit;
      text-decoration: none;
      &.icon {
        align-self: flex-start;
      }
    }
    span {
      display: inline-block;
      align-self: flex-start;
      padding: .25em;
      font-size: 1rem;
      line-height: 1;
      color: #fff;
      transition: .25s ease all;
      background: #08f;
      /* &:hover,
      &:focus {
        transform: scale(1.1);
      } */
      &.type {
        font-size: .5em;
        display: none;
        .isDev & {
          display: block;
        }
        &.scraped {
          background: #6b6;
        }
        &.trello {
          background: darkblue;
        }
        &.merged {
          background: darkblue;
        }
      }
    }
  }
  p {
    margin: 1rem;
    padding: 0;
    text-align: left;
  }
  h1, p {
    max-width: 42rem;
  }
  h1 {
    text-align: left;
    padding: 0 .5em;
    width: 100%;
    box-sizing: border-box;
    max-width: 24em;
  }
  a.src {
    max-width: 12em;
    font-size: .8em;
    display: inline-block;
    vertical-align: middle;
    overflow: clip;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    transition: 1s ease max-width;
    &:hover, &:focus {
      text-decoration: underline;
      max-width: 32em;
    }
  }
  .graphic {
    max-height: 100vh;
    transition: 1s ease all;
  }
  img {
    transition: 1s ease all;
    background: #8882;
    margin: 0 auto;
    padding: 0;
    max-width: 100vw;
    height: auto;
    max-height: 24em;
    width: auto;
  }
  .zoom {
    &.graphic {
      max-height: none;
    }
    .image {
      max-height: none;
    }
    img {
      max-width: 100vw;
      height: auto;
      max-height: none;
    }
  }
`

const TITLE = styled(Link)`
  flex: 1;
  &:hover {
    text-decoration: underline;
  }
`

const NoImg = styled.span`
  padding: 1em;
  color: #0008;
  display: inline-block;
`

const ZoomButton = styled.button`
  background: none;
  border: none;
  padding: .25em;
  transition: 1s ease-out all;
  &:hover, &:focus {
    transform: scale(1.5);
    transform-origin: top left;
    ${props => props.zoom && `
      transform: scale(.75);
      transform-origin: top left;
    `}
  }
`

const Image = styled.div`
  position: relative;
  max-width: 100vw;
  margin: auto;
  overflow: auto;
  flex: 1;
  background: #eee;
  cursor: zoom-in;
`

// function ocrTitleMatch(s) {
//   let capitals = s.length - s.replace(/[A-Z]/g, '').length
//   let lowercase = s.length - s.replace(/[a-z]/g, '').length
//   let capitalized = capitals / (capitals + lowercase)
//   return capitalized > .8 && s.length > 10
// }

const Text = styled.section`
  max-width: 36rem;
  margin: auto;
`

const Year = styled(Link)`
  font-size: .75em;
  background: #08f;
  color: #fff !important;
  padding: .25em;
`

function simpleURL(url) {
  return url.slice(url.indexOf('://') + 3).replace('www.', '')
}

function Page({ clipping = {}, clippingsURL = '/clippings', ...props }) {
  const [zoom, setZoom] = useState(false)

  let { year, title } = clipping

  const titleRef = useRef(null)

  useEffect(() => {
    let oldTitle = document.title
    if (title) {
      document.title = `${clipping.list.emoji} ${title} - Pessimists' Archive`
    }
    return () => {
      document.title = oldTitle
    }
  }, [title, clipping?.list?.emoji])

  useEffect(() => {
    let scroll = setTimeout(() => titleRef.current.scrollIntoView(), 100)
    return () => {
      clearTimeout(scroll)
    }
  }, [clipping.id])

  let host = global.location?.host || `https://${WEBSITE_DOMAIN}`
  let linkURLs = clipping.trello?.attachments?.filter(a => !a.isUpload).map(c => c.url) || (clipping.scraped?.URL ? [clipping.scraped.URL] : null) || null
  return (<ARTICLE {...props}>
    <Text ref={titleRef}>
      <h1>
        <TITLE to={clipping.url}>{title}</TITLE>
        <span className={`type ${clipping.type}`}><a href={`https://docs.google.com/spreadsheets/d/1aToPBUS8wBXxM3AEXEs15g8rbZqYx7SoxM5_lllIcPE/edit#gid=2109819586&range=K${clipping.index + 2}`}>{clipping.type}</a></span>
        <Year to={linkURLs[0] || ''}>{year}</Year>
        <Share title={title || 'Check out this clipping on @PessimistsArc!'} url={`https://${host}${clipping.url}`} />
      </h1>
      {clipping.description ? <p>{clipping.description}</p> : null}
      {linkURLs ? <p>🔗 {linkURLs.map(url => <a href={url} className="src" target="_blank" title={url} rel="noreferrer">{simpleURL(url)}</a>)}</p> : null}
    </Text>
    <section className={`graphic ${zoom && 'zoom'}`} key={clipping.id}>
      <HeartableLink id={clipping.id} />
      <ZoomButton className="left" title={`${zoom ? 'Shrink' : 'Enlarge'} image`} onClick={() => setZoom(!zoom)} zoom={zoom}>🔍</ZoomButton>
      <Image onClick={() => setZoom(!zoom)}>
        {clipping.image.url ? <img alt={clipping.OCR} src={clipping.image.url} width={clipping.image.width} height={clipping.image.height} /> : <NoImg>No image</NoImg>}
      </Image>
    </section>
    <Log data={{ clipping }} />
  </ARTICLE>)
}

export default Page
