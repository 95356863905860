import React, { useEffect } from 'react'
// import { TwitterTweetEmbed } from 'react-twitter-embed'

// useEffect(() => {
//   const scripts = [...document.head.getElementsByTagName('script')]
//   if (!scripts.find(script => script.src === twitterSrc)) {
//     let script = document.createElement('script')
//     script.src = twitterSrc
//     script.charset = 'utf-8'
//     document.head.appendChild(script)
//     return () => {
//
//     }
//   }
// })

const byClass = cls => [...document.getElementsByClassName(cls)]

function Tweet({ children, author, href, date }) {
  // return <TwitterTweetEmbed tweetId={href.split('status/')[1].split('?')[0]} />
  useEffect(() => {
    global.twttr?.widgets.load()
      .then(() => {
        byClass('twitter-tweet').forEach(tweet => {
          let iframes = tweet.getElementsByTagName('iframe')
          console.log({ tweet, iframes })
          if (iframes && iframes[0]) iframes[0].style.height = ''
        })
      })
  })
  return (<div key={href}>
    <blockquote className="twitter-tweet" style={{ maxWidth: '16em', margin: 'auto' }}>
      <p lang="en" dir="ltr">{children}</p>
      &mdash; {author}
      <a href={href}>{date}</a>
    </blockquote>
  </div>)
}

export default Tweet
