import React from 'react'

import PAGE from './PAGE'
import Button from '../ExploreLink'

function Manifesto() {
  return (<PAGE title="Newsletter">
    <p>The Pessimists Archive newsletter gives you a weekly roundup of new and old finds shared on our social accounts.</p>
    <p>Each clipping has a little more context and information than on social media.</p>
    <Button to="https://pessimistsarchive.substack.com/">Read Latest</Button>
    <iframe title="substack" src="https://pessimistsarchive.substack.com/embed" width="420" height="120" frameborder="0" scrolling="no" />
  </PAGE>)
}

export default Manifesto
