import React, { useState, useEffect, useRef } from 'react'
/* eslint no-unused-vars: "off" */
import { Engine, Render, World, Bodies, Body, /*Mouse, MouseConstraint,*/ Events } from 'matter-js'
import styled, { keyframes } from 'styled-components'

import useWindowSize from './hooks/useWindowSize'

const { localStorage } = global

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  animation: ${fadeIn} 4s ease;
  &:active {
    /* z-index: 5; */
  }
`

const Box = styled.div`
  display: flex
  position: relative;
  height: 100vh;
`


const VELOCITY_SCALE = 8

const Component = ({ images, years, scale = 1.5, highlight = null, onClick, ...props }) => {
  const [clicked] = useState()
  const { width, height } = useWindowSize()
  let mobile = width < height
  const boxRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    let globalScale = mobile ? .5 : 1
    let engine = Engine.create({})
    let render = Render.create({
      element: boxRef.current,
      engine: engine,
      canvas: canvasRef.current,
      options: {
        width,
        height,
        background: '#8880',
        wireframes: false
      }
    })

    let padding = 16
    const floors = ([
      // N W S E
      [width / 2, 0,        width + padding, 1],
      [width / 2, height,   width + padding, 1],
      [0, height / 2,       1, height + padding],
      [width, height / 2,   1, height + padding]
    ]).map(([x, y, w, h]) => Bodies.rectangle(x, y, w, h, { isStatic: true, render: { fillStyle: '#000' } }))

    // let defaultSize = 100
    const rectangles = images.map(image => {
      const x = Math.round(Math.random() * width)
      const y = Math.round(Math.random() * height)
      let w = image.width
      let h = image.height

      let scale = image.scale * globalScale
      return Bodies.rectangle(x, y, w * scale, h * scale, {
        // id: clicked,
        isActive: false,
        render: {
          // opacity: .5,
          sprite: {
            xScale: scale,
            yScale: scale,
            texture: image.url,
          }
        },
        // speed: 122, angle: (Math.random() * Math.PI) - (Math.PI / 2)
      })
    })

    // const mouse = Mouse.create(render.canvas)
    // const mouseConstraint = MouseConstraint.create(engine, {
    //   mouse,
    //   constraint: {
    //     stiffness: 1,
    //     render: { visible: false }
    //   }
    // })

    // function mouseDown(e) {
    //   const { body } = e.source
    //   // const clickedId = body?.id
    //   if (body) {
    //     if (!body.isActive) {
    //       body.isActive = true
    //       body.render.sprite.xScale = globalScale
    //       body.render.sprite.yScale = globalScale
    //       Body.scale(body, 2, 2)
    //       //Body.setAngle(body, 0)
    //       //Body.setAngularVelocity(body, 0)
    //     } else {
    //       body.isActive = false
    //       body.render.sprite.xScale = globalScale
    //       body.render.sprite.yScale = globalScale
    //       Body.scale(body, globalScale, globalScale)
    //     }
    //   }
    //   // if (clickedId && onClick) {
    //   //   onClick(clickedId)
    //   //   // setClicked(clickedId)
    //   // }
    // }
    // Events.on(mouseConstraint, 'mousedown', mouseDown)
    // Events.on(mouseConstraint, 'mouseup', e => {
    //   // ?e.source.blur()
    //   if (clicked) {
    //     if (onClick) onClick(clicked)
    //     setClicked()
    //   }
    // })
    engine.gravity.y = 0
    engine.gravity.x = 0
    // render.mouse = mouse
    World.add(engine.world, [...floors, ...rectangles/*, mouseConstraint */])
    Engine.run(engine)
    Render.run(render)
    // let timeout = setTimeout(() => {
      rectangles.forEach(rect => Body.setVelocity(rect, { x: (VELOCITY_SCALE / 2) - (Math.random() * VELOCITY_SCALE), y: (VELOCITY_SCALE / 2) - (Math.random() * VELOCITY_SCALE) }))
      rectangles.forEach(rect => Body.setAngularVelocity(rect, (Math.random() * .01)))
    // }, 500)
    return () => {
      Render.stop(render)
      World.clear(engine.world)
      Engine.clear(engine)
      render.canvas.remove()
      render.canvas = null
      render.context = null
      render.textures = {}
      // Events.off(mouseConstraint, 'mousedown', mouseDown)
    }
    // return () => clearTimeout(timeout)
  }, [width, height, images.length, clicked, images, onClick, mobile])

  if (!['true', null].includes(localStorage?.getItem('show-physics'))) return null

  return (<Container {...props}>
    <Box ref={boxRef}>
      <canvas ref={canvasRef} style={{ maxHeight: '100%' }} />
    </Box>
  </Container>)
}

// const NoComponent = () => <div />

export default Component
