import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'

import Explore from '../ExploreLink'
import Tweet from '../Tweet'

import Lists from '../data/lists'

const readingsByList = {
  'radio': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/Weird--Ghostly-and-Supernatural-Antics-of-the-Radio-1931-e19ijqr',
    'https://anchor.fm/pessimistsaloud/embed/episodes/The-Lazy-Half-Wit-Comes-Into-His-Own---A-British-Writer-Reveals-His-Views-on-The-Drawbacks-of-Mass-Communication-1953-e15bok7/a-a68u1i0'
  ],
  'recorded-sound': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/The-Lazy-Half-Wit-Comes-Into-His-Own---A-British-Writer-Reveals-His-Views-on-The-Drawbacks-of-Mass-Communication-1953-e15bok7/a-a68u1i0'
  ],
  'television': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/The-Lazy-Half-Wit-Comes-Into-His-Own---A-British-Writer-Reveals-His-Views-on-The-Drawbacks-of-Mass-Communication-1953-e15bok7/a-a68u1i0'
  ],
  'airplane': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/A-Skeptical-Nation-Visits-Upon-the-Airplane-the-Doubts-it-Once-Felt-for-the-Automobile---The-New-York-Times--1928-e17vbgo/a-a6jk265',
    'https://anchor.fm/pessimistsaloud/embed/episodes/The-Flying-Machines-Which-Do-Not-Fly---The-New-York-Times--October-9th--1903-e14nalu/a-a660ju3',
    'https://anchor.fm/pessimistsaloud/embed/episodes/Reading-in-Bed-1909---Aeroplane-Face-1908-e12v8m5/a-a60a7t1'
  ],
  'vaccines': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/An-Absurd-Prejudice---The-New-York-Times--1875-e16i3nn/a-a6e3m3a'
  ],
  'photography': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/Photographs-are-made-to-lie-1897---Bicycle-Insanity-1896-e14gkqo/a-a6536f7'
  ],
  'bicycle': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/Photographs-are-made-to-lie-1897---Bicycle-Insanity-1896-e14gkqo/a-a6536f7'
  ],
  'mirrors': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/Those-Deadly-Little-Mirrors---Chicago-Tribune--1912-e13rh9d/a-a62ab4r'
  ],
  'novels': [
    'https://anchor.fm/pessimistsaloud/embed/episodes/Reading-in-Bed-1909---Aeroplane-Face-1908-e12v8m5/a-a60a7t1'
  ],
}

const Nav = styled(Link)`
  display: block;
  user-select: none;
  min-width: 1em;
  font-size: 2em;
  line-height: 1;
  color: #0006;
  text-decoration: none;
  border-radius: .25em;
  transition: .2s ease all;
  display: inline-block;
  @media (min-width: 32em) {
    font-size: 2em;
    padding: .125em;
  }
  &.active {
    pointer-events: none;
    color: #0001;
  }
  border-radius: 50%;
  &:hover,
  &:focus {
    color: #08f;
    transform: scale(1.5);
    background: #08f;
    color: #fff;
  }
`

const TITLE = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 32em;
  h1 {
    flex: 1;
    margin: 0;
    font-size: 1.5em;
    > a {
      color: inherit;
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`

const TitleLink = styled(Link)`
  &.active {
    pointer-events: none;
  }
  .emoji {
    display: inline-block;
    transition: .5s ease all;
  }
  &:not(.active):hover,
  &:not(.active):focus {
    text-decoration: none;
    .emoji {
      transform: scale(1.5);
    }
  }
`

const DESCRIPTION = styled.div`
  max-width: 32em;
  position: relative;
  h1 {
    text-transform: uppercase;
    font-size: 1.75em;
    line-height: 1;
  }
  z-index: 4;
  padding: 1em;
  background: var(--main-bg-alpha);
  backdrop-filter: blur(3px);
  margin: auto;
  border-radius: 1em;
`

function ListLink({ list, children }) {
  if (!list) return <span>{children}</span>
  return <Nav to={list.list.url} title={`${list.list.emoji} ${list.list.name}`}>{children}</Nav>
}

const Readings = styled.div`
  padding-top: 1.5em;
  iframe {
    width: calc(100% - 1em);
  }
`

const H2 = styled.h2`
  text-align: left;
  font-size: 1.25em;
  text-transform: uppercase;
  margin: .25em 0 .75em 0;
`

function Intro({ list, listIndex, subpage, lists }) {
  let prevCat, nextCat, startCat, endCat
  startCat = Lists[0]
  if (list) {
    endCat = Lists[Lists.length - 1]
    prevCat = Lists[listIndex - 1] || startCat
    nextCat = Lists[listIndex + 1] || endCat
  }

  return (<DESCRIPTION>
    <TITLE>
      <span>
        <ListLink list={startCat}>«</ListLink>
        <ListLink list={prevCat}>‹</ListLink>
      </span>
      <h1><TitleLink to={list.list.url} exact><span className="emoji">{list.list?.emoji}</span> {list.list?.name}</TitleLink></h1>
      <span>
        <ListLink list={nextCat}>›</ListLink>
        <ListLink list={endCat}>»</ListLink>
      </span>
    </TITLE>
    {list?.list?.slug === 'masks' && (<video width={200} height={200} autoPlay><source src="/masks.webm" type="video/webm" /></video>)}
    {list?.list?.description && <p>{list.list?.description}</p>}
    {subpage ? null : <Explore to={`${list.list?.url}${!subpage ? '/clippings' : ''}`}>{list.entries?.length} Clippings</Explore>}
    {!subpage && list.list.slug in readingsByList && (<Readings>
      <H2>Article Readings</H2>
      {readingsByList[list.list.slug].map(src => <iframe src={src} height={104} width={420} scrolling="no" style={{ border: 0 }} />)}
    </Readings>)}
    {list.list.tweets.map(([children, author, href, date]) => <Tweet {...{ children, author, href, date }} />)}
  </DESCRIPTION>)
}

export default Intro
