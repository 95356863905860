import React from 'react'
import { NavLink as A } from 'react-router-dom'
import styled from 'styled-components'

import Page from './Page'
import Link from './Link'
import NewsletterEmbed from '../components/NewsletterEmbed'

const Thumbs = styled.ol`
  overflow: visible;
  /* max-height: 100vh; */
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  ${props => props.hasCard && `
    font-size: .75em;
  `}
  > li {
    display: inline-block;
    list-style: none;
    margin: .5em;
    padding: 0;
  }
`

const LIST = styled.div`
  position: relative;
`

const NotFound = styled.div`
  position: relative;
  margin: auto;
  padding: 1em;
  max-width: 32em;
  z-index: 4;
  border-radius: 1em;
  background: var(--main-bg-alpha);
  backdrop-filter: blur(3px);
`

function List({ list, items, cat, year, index }) {
  let clipping
  if (year && index) clipping = items.find(card => card.year === year && card.id === index)
  return (<LIST>
    {clipping && <Page key={index} clipping={clipping} />}
    {index && !clipping && <NotFound><h1>Clipping not found</h1></NotFound>}
    {clipping && (<>
      <p>
        <A to={`${list.list.url}/clippings`}>Back to list</A> &nbsp;
        <a href="https://bit.ly/3C81iiU">Newsletter</a> &nbsp;
        <a href="https://apple.co/3nB9hkv">Podcast</a>
      </p>
      <NewsletterEmbed showCallToAction={false} />
    </>)}
    <Thumbs hasCard={clipping}>
      {items?.map(card => (<li key={card.id}>
        <Link to={card.url} clipping={card} />
      </li>))}
    </Thumbs>
  </LIST>)
}

export default List
