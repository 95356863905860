import React from 'react'

import PAGE from './PAGE'
import Button from '../ExploreLink'

function Readings() {
  return (<PAGE title="Pessimists Aloud Podcast">
    <p><b>Pessimists Aloud</b> is a weekly podcast of the best Pessimists Archive articles read aloud. Each episode is performed by a professional voice actor and has digitized records as backing music.</p>
    <iframe title="anchor.fm" src="https://anchor.fm/pessimistsaloud/embed" height="100px" width="500px" frameborder="0" scrolling="no" />

    <Button to="https://anchor.fm/pessimistsaloud">Subscribe</Button>
  </PAGE>)
}

export default Readings
