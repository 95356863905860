import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'

import Header from './Header'

import Timeline from './Timeline'

import Favorites from './Favs'
import ListIndex from './List/Index'

import Contact from './Pages/Contact'
import Manifesto from './Pages/Manifesto'
import Newsletter from './Pages/Newsletter'
import Readings from './Pages/Readings'

const APP = styled.div`
  font-family: Courier, monospace;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  text-align: center;
  color: var(--main-fg);
  scroll-behavior: smooth;
  --main-bg: #fff;
  --main-bg-alpha: #fffc;
  --main-fg: #000;
  --button-bg: #eee;
`

let devURLMarkers = ['localhost', 'netlify', 'herokuapp']

function App() {
  let mode = devURLMarkers.some(marker => global.location?.hostname?.includes(marker)) ? 'isDev' : ''

  return (<APP className={mode}>
    <Header />
    <Route exact path={["/", "/list/:listId?", "/list/:listId?/clippings/:year?/:index?"]} render={({ match: { params } }) => <ListIndex {...params} />} />
    <Route exact path="/favs" component={Favorites} />
    <Route exact path="/tweets" component={Timeline} />
    <Route exact path="/about" component={Contact} />
    <Route exact path="/manifesto" component={Manifesto}/>
    <Route exact path="/newsletter" component={Newsletter}/>
    <Route exact path="/readings" component={Readings} />
  </APP>)
}

export default App
