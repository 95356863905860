export const WEBSITE_DOMAIN = 'pessimistsarchive.org'

// to redact a clipping by its id
// add an ID to this array
export const redactedClippings = [
  'm-sc-639-420',
  'm-sc-737-428',
  'm-sc-776-478',
  'sc-754',
  'sc-772',
  'sc-767',
  'sc-759',
  'sc-741',
  'sc-775',
  'sc-773',
  'sc-738',
  'sc-778',
  'sc-751',
  'sc-756'
]