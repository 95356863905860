import React, { useState, useCallback } from 'react'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'

function range(start, end) {
  return Array.from({ length: end - start + 1 }, (_, i) => i + start)
}

function getYears(min, max) {
  return range(min, max)
}

const TIMELINE = styled.div`
  position: relative;
  position: fixed;
  bottom: 0;
  overflow: auto;
  max-width: 100vw;
  min-height: 7rem;
  height: 7rem;
  max-height: 32vh;
  font-size: 1em;
  box-shadow: 0 0 2em 0 #0002;
  border-top: 1px solid #ccc;
  transition: .5s ease all;
  bottom: 0;
  z-index: 5;
  &:hover, &:focus-within {
    background: #fffd;
  }
`

const Years = styled.ol`
  display: flex;
  background: #6662;
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overflow-y: clip;
  transition: .5s ease box-shadow;
  &:hover, &:focus-within {
    box-shadow: inset -1.5em 0 1em -1em #0006, inset 1.5em 0 1em -1em #0006;
  }
  > li {
    position: relative;
    flex: 1;
    min-width: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .2s ease all;
    &:nth-child(1) {
      background: linear-gradient(to right, #ffff, #fff0);
      min-width: 2em;
    }
    &:last-child {
      background: linear-gradient(to left, #ffff, #fff0);
      min-width: 2em;
    }
    /* &:hover {
      > strong {
        color: #000;
        font: inherit;
        z-index: 2;
        .decade {
          opacity: 1;
          color: inherit;
        }
      }
    } */
  }
`

const Year = styled.li`
  ${props => props.isYear && `
    background: #ddd8;
    strong {
      font-size: 1em;
      .century {
        font-size: 0;
      }
    }
  `}
  ${props => props.isOdd && `
    ol {
      background: #0001;
    }
  `}
`

const YearLabel = styled.strong`
  position: absolute;
  top: 4rem;
  left: -2em;
  right: -1em;
  text-align: center;
  color: #666e;
  font-size: 0;
  display: inline-block;
  padding: .25em;
  ${props => (props.isDecade || props.isCentury) && `
    font-size: 1em;
    padding-top: 1em;
    &::after {
      content: '';
      height: 1em;
      border-left: .1em solid #aaa;
      position: absolute;
      top: 0;
      left: 50%;
      color: #ddd;
    }
  `}
  ${props => (props.isDecade && !props.isCentury) && `
    color: #444;
    .century {
      display: none;
      display: inline;
      opacity: .5;
    }
  `}
  ${props => props.isCentury && `
    font-size: 1.5em;
    padding-top: 1rem;
    margin-left: -1rem;
    .decade {
      opacity: .25;
    }
    &::after {
      height: 1rem;
      border-width: .2em;
    }
  `}
`

const Entries = styled.ol`
  position: relative;
  overflow: visible;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: .1em solid #bbb;
  height: 4em;
  background: #bbb4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  > li {
    > a.category {
      position: relative;
      display: inline-block;
      box-sizing: content-box;
      font-size: 1.5em;
      line-height: 1;
      cursor: pointer;
      color: inherit;
      width: 1em;
      top: .25em;
      left: -.6em;
      text-align: center;
      z-index: 1;
      text-decoration: none;
      transition: .5s ease transform;
      transform-origin: 50% 50%;
      outline: 0;
      &:focus {
        outline: 0;
        color: #08f;
      }
      &.active {
        z-index: 2;
        transform: scale(1.5);
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          /* top: -.5em; */
          bottom: 1rem;
          z-index: -1;
          height: 1.25em;
          width: 2px;
          margin-left: -1px;
          background: linear-gradient(#8888, #8880);
        }
      }
      &:hover, &:focus {
        z-index: 3;
        transform: scale(2);
      }
    }
  }
`

function Timeline({ lists, category, year }) {
  const [range] = useState(() => [Math.min(...lists.map(list => list.list.year)) - 2, Math.max(...lists.map(list => list.list.year)) + 2])
  const years = getYears(...range)

  const autoFocus = useCallback(el => el ? el.focus() : null, [])

  function scrollToClickedCategory(e, absNavPos) {
    const parent = e.target.parentElement.parentElement.parentElement.parentElement
    const scrollTo = (parent.scrollWidth - parent.clientWidth) * absNavPos
    parent.scrollLeft = scrollTo
  }

  return (<TIMELINE>
    <Years>
      {years.map((y, key) => {
        const yearLists = lists.filter(list => list.list.year === y)
        return (<Year key={key} id={y} isActive={yearLists.find(item => item.list.slug === category)} isYear={Number(year) === Number(y)} isOdd={(Math.floor(y / 10) % 2)}>
          <Entries>
            {yearLists.map(({ list }, itemKey) => (<li key={itemKey}>
              <Link className="category" ref={(list.slug === category) ? autoFocus : null} to={list.url} title={list.name} onClick={e => scrollToClickedCategory(e, (y - range[0]) / (range[1] - range[0]))}>{list.emoji}</Link>
            </li>))}
          </Entries>
          <YearLabel isDecade={y % 10 === 0} isCentury={y % 100 === 0}>
            <span className="century">{y.toString().slice(0, 2)}</span>
            <span className="decade">{y.toString().slice(2, 4)}</span>
          </YearLabel>
        </Year>)
      })}
    </Years>
  </TIMELINE>)
}

export default Timeline
